import React from "react"
// import { Link } from "gatsby"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
const Navigation = () => <nav>
  <AniLink cover to="/" activeStyle={{opacity: .25}} direction="right"  bg="#111">Zoom Black</AniLink>
  <AniLink cover to="/zoom-white" activeStyle={{opacity: .25}} direction="right" bg="#FFF">Zoom White</AniLink>
  <AniLink cover to="/loop-black" activeStyle={{opacity: .25}} direction="right"  bg="#111">Loop Black</AniLink>
  <AniLink cover to="/loop-white" activeStyle={{opacity: .25}} direction="right"  bg="#fff">Loop White</AniLink>
</nav>
export default Navigation
