import React from "react"

const VideoContainer = ({src}) => (
  <div className="videoWrapper">
  <video
    id="videocontainer"
    tabindex="0"
    controls="true"
    autobuffer="true"
    preload="true"
    loop="true"
  >
    <source
      type="video/mp4"
      codecs="avc1.42c00d,mp4a.40.2"
      src={src}
    ></source>
    Sorry, your browser does not support the &lt;video&gt; element.
  </video>
</div>
)


export default VideoContainer